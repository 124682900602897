import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home'
import Polls from './pages/Polls'
import Documents from "./pages/Documents";
import News from "./pages/News";

import PollsHome from "./pages/PollsHome";
import About from "./pages/About";
import Contato from "./pages/Contato";



export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/participe" element={<Polls />} />
        <Route path="/pesquisa" element={<PollsHome />} />
        <Route path="/documentos" element={<Documents />} />
        <Route path="/noticias" element={<News />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/sobrenos" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

