import './ProductsContent.css'
// import { useState } from 'react';

import { FaChevronUp, FaDownload } from 'react-icons/fa';
// import { FaFilePdf, FaFileWord, FaFileImage, FaFileCsv, FaFileExcel, FaFile, FaChevronDown, FaChevronUp, FaDownload } from 'react-icons/fa';
// import planejamentoExecutivoPreliminar from '../../uploads/2024.01.15_P3_VP_PMDPA.pdf';
// import planejamnentoExecutivoOficial from '../../uploads/Planejamento executivo - versão oficial.pdf';
// import planoParticipacaoSocialOficial from '../../uploads/Plano de Comunicação e Participação social - Versão Oficial.pdf';
// import diagnosticoTécnico from '../../uploads/2024.04.17_P3_VP_PMDPA.pdf';
// import diagnosticoTécnico2 from '../../uploads/2024.04.05_PRODUTO3.2_R1_PMDPA.pdf';
// import PrognósticoCenárioAtual1 from '../../uploads/2024.04.05_PRODUTO4.1_R1_PARTE1_PMDPA.pdf';
// import PrognósticoCenárioAtual2 from '../../uploads/2024.04.10_PRODUTO4.1_R1_PARTE2_PMDPA.pdf';
// import PrognósticoFuturosZoneamento from '../../uploads/2024.04.16_PRODUTO4_R2_PMDPA.pdf';
// import ApendiceD from '../../uploads/2024.04.16_APENDICE_D_compressed.pdf';

function ProductsContent() {
    // const fileTypeToIcon = {
    //     pdf: FaFilePdf,
    //     doc: FaFileWord,
    //     docx: FaFileWord,
    //     jpg: FaFileImage,
    //     png: FaFileImage,
    //     csv: FaFileCsv,
    //     xls: FaFileExcel,
    //     xlsx: FaFileExcel,
    // };

    const products = [
        {
            cardId: 1,
            cardName: "Produto 1 - Planejamento Executivo",
            files: [
                {
                    path: 'https://dacengenharia.s3.sa-east-1.amazonaws.com/Macro+PA/Produto+1/2023.09.22_P1_VP_PMDPA.pdf',
                    filename: "Planejamento executivo - versão preliminar",
                    extension: "pdf"
                },
                {
                    path: 'https://dacengenharia.s3.sa-east-1.amazonaws.com/Macro+PA/Produto+1/Planejamento+executivo+-+VF.pdf',
                    filename: "Planejamento executivo - versão oficial",
                    extension: "pdf"
                }
            ]
        },
        {
            cardId: 2,
            cardName: "Produto 2 - Plano de Comunicação e Participação social",
            files: [
                {
                    path: 'https://dacengenharia.s3.sa-east-1.amazonaws.com/Macro+PA/Produto+2/2024.04.05_P2_VO_PMDPA.pdf',
                    filename: "Plano de Comunicação e Participação social - Versão oficial",
                    extension: "pdf"
                },
            ]
        },
        {
            cardId: 3,
            cardName: "Produto 3 - Relatório 1 - Diagnóstico técnico",
            files: [
                {
                    path: 'https://dacengenharia.s3.sa-east-1.amazonaws.com/Macro+PA/Produto+3/2024.04.17_P3_VP_PMDPA.pdf',
                    filename: "Diagnostico Técnico - Versão preliminar",
                    extension: "pdf"
                },
            ]
        },
        {
            cardId: 4,
            cardName: "Produto 3 - Relatório 2 - Diagnóstico participativo",
            files: [
                {
                    path: 'https://dacengenharia.s3.sa-east-1.amazonaws.com/Macro+PA/Produto+3/2024.04.05_PRODUTO3.2_R1_PMDPA.pdf',
                    filename: "Diagnostico Participativo - Versão preliminar",
                    extension: "pdf"
                },
            ]
        },
        {
            cardId: 5,
            cardName: "Produto 4 - Relatório 1 - Prognóstico Cenário Atual",
            files: [
                {
                    path: 'https://dacengenharia.s3.sa-east-1.amazonaws.com/Macro+PA/Produto+4/2024.04.25_PRODUTO4.1_R1_PARTE1_PMDPA.pdf',
                    filename: "Prognóstico Cenário Atual - Versão preliminar - Parte 1",
                    extension: "pdf"
                },
                {
                    path: 'https://dacengenharia.s3.sa-east-1.amazonaws.com/Macro+PA/Produto+4/2024.06.12_PRODUTO4.1_R1_PARTE2_PMDPA.pdf',
                    filename: "Prognóstico Cenário Atual - Versão preliminar - Parte 2",
                    extension: "pdf"
                },
            ]
        },
        {
            cardId: 6,
            cardName: "Produto 4 - Relatório 2 - Prognóstico Cenários Futuros e Zoneamento",
            files: [
                {
                    path: 'https://dacengenharia.s3.sa-east-1.amazonaws.com/Macro+PA/Produto+4/2024.06.12_PRODUTO4_R2_PMPA+.pdf',
                    filename: "Prognóstico Cenário Atual - Versão preliminar - Parte 1",
                    extension: "pdf"
                },
                {
                    path: 'https://dacengenharia.s3.sa-east-1.amazonaws.com/Macro+PA/Produto+4/2024.04.16_APENDICE_D.pdf',
                    filename: "Apêndice D",
                    extension: "pdf"
                },
            ]
        },
    ]


    const handleCardChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.checked = !e.target.checked;
        let cardId = e.target.id.split('-')[1];
        const cardList = document.getElementById('cardList-' + cardId);
        const cardContent = document.getElementById('cardContent-' + cardId);
        const cardChevron = document.getElementById('cardChevron-' + cardId);

        let currentRotation = parseInt(cardChevron.style.transform.replace('rotate(', '').replace('deg)', ''), 10) || 0;

        if (cardContent.style.maxHeight === '100vh') {
            cardContent.style.maxHeight = '0vh';
            cardChevron.style.transform = `rotate(${currentRotation - 180}deg)`;
            cardList.style.borderRadius = "10px";
            cardList.style.boxShadow = "0px 6px 2px 0px rgb(150, 140, 140)";
        } else {
            cardList.style.borderRadius = "10px 10px 0px 0px";
            cardContent.style.maxHeight = '100vh';
            cardChevron.style.transform = `rotate(${currentRotation + 180}deg)`;
            cardList.style.boxShadow = "0px 0px 0px 0px ";
        }


    };

    return (
        <div className='productsContent'>
            <div className='productsTitle'>
                <h1>DOCUMENTOS OFICIAIS</h1>
                <h2>Confira o conteúdo elaborado até o momento do Plano!</h2>
                {/* <p>Tenha acesso a todos os documentos oficiais (Versões prévias, Justificadas, Oficiais,
                    Memoriais Técnicos e Produtos) do Plano de Mobilidade de Itajubá.</p> */}
            </div>
            <div className="center">
                {(() => {
                    if (products) {
                        return products.map((product) => {

                            return (
                                <div key={product.cardId} className="cardProduct">
                                    <label className="cardList" id={"cardList-" + product.cardId}>
                                        <div className="cardTitle">
                                            <h2>{product.cardName}</h2>
                                        </div>
                                        <FaChevronUp className="cardChevron" id={"cardChevron-" + product.cardId} />
                                        <input type="checkbox" name="card-toggle" id={"cardToggle-" + product.cardId} onChange={(e) => handleCardChange(e)} hidden defaultChecked="true" />

                                    </label>
                                    <div className="cardContent" id={"cardContent-" + product.cardId}>
                                        <ul className='fileList' style={{ width: '100%' }}>
                                            {(() => {
                                                if (product.files) {
                                                    return product.files.map(({ path, filename, extension }) => {
                                                        return (
                                                            <li className='fileListItem'>
                                                                <div    >
                                                                    <h4>{filename}</h4>
                                                                    <div className='fileTitle'>
                                                                        <a className='fileTitle' target="_blank" href={path} download>
                                                                            <FaDownload />
                                                                            <h4>Download</h4>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    });
                                                }
                                            })()}
                                        </ul>
                                    </div>

                                </div>
                            )
                        })
                    }
                })()}
            </div>
        </div>
    );
}

export default ProductsContent;
