import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Login from '../User/LoginForm/Login';
import Register from '../User/RegisterForm/Register';
import Redefine from '../User/RedefinePassword/RedefinePassword';


import './Navbar.css';
import { HiChevronDown } from "react-icons/hi";
import dac from '../../assets/images/DAC_1.png';
import prefeitura from '../../assets/images/logo_PA.png';
import { useCookies } from 'react-cookie';
import { useAuth } from '../../contexts/AuthContext';

import DownloadFile from '../DownloadFile/DownloadFile';

function Navbar() {
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const [isRedefineOpen, setIsRedefineOpen] = useState(false);
    const [toggle, setToggle] = useState(true);
    const [dummy, setDummy] = useState(false);
    const [cookies] = useCookies(['dac-auth-user']);

    const [selectedItem, setSelectedItem] = useState(null);

    const { signOut } = useAuth()

    const location = useLocation(); // Obtenha a localização atual da rota

    const handleSelectItem = (index) => {
        setSelectedItem(index);
    };

    const updateSelectedItem = () => {
        const pathname = location.pathname;
        const items = ["/", "/participe", "/documentos", "/noticias", "/contato", "/sobrenos"];
        const index = items.indexOf(pathname);
        setSelectedItem(index);
    };

    useEffect(() => {
        updateSelectedItem();
    }, [location]);



    return (
        <>
            <div className={toggle ? 'Navbar-collapsed' : 'Navbar'}>
                <div className='navbar-menu'>
                    <div className="navbar--logo">
                        <img className='logo-prefeitura' src={prefeitura} alt="Prefeitura de Itajubá" />
                        {/* <img className='logo-plano' src={dac} alt="DAC - Engenharia" /> */}
                    </div>


                    <div className="navbar--features">
                        <ul className='list'>
                            <li className={selectedItem === 0 ? 'li-selected' : ''}><Link to="/" onClick={() => handleSelectItem(0)}>Home</Link></li>
                            <li className={selectedItem === 1 ? 'li-selected' : ''}><Link to="/participe" onClick={() => handleSelectItem(1)}>Participe</Link></li>
                            <li className={selectedItem === 2 ? 'li-selected' : ''}><Link to="/documentos" onClick={() => handleSelectItem(2)}>Documentos</Link></li>
                            <li className={selectedItem === 3 ? 'li-selected' : ''}><Link to="/noticias" onClick={() => handleSelectItem(3)}>Notícias</Link></li>
                            <li className={selectedItem === 4 ? 'li-selected' : ''}><Link to="/contato" onClick={() => handleSelectItem(4)}>Contato</Link></li>
                            <li className={selectedItem === 5 ? 'li-selected' : ''}><Link to="/sobrenos" onClick={() => handleSelectItem(5)}>Sobre Nós</Link></li>
                        </ul>
                        {cookies['dac-auth-role'] === 'administrador' ? <DownloadFile /> : null}
                    </div>
                    <div className='navbar--login'>
                        {/* {cookies['dac-auth-user'] === undefined ? (
                            <div className='navbar--login-buttons'>
                                <button type="button" className='nav-button' onClick={() => { setIsLoginOpen(true) }} >Entrar</button>
                                <button type="button" onClick={() => { setIsRegisterOpen(true) }}>Cadastre-se</button>
                            </div>
                        ) : (
                            <div className="nav-user">
                                <p className="nav-name">Olá <b>{cookies['dac-auth-user'].split(' ')[0]}</b>!</p>
                                <button><p className="link-blue" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    signOut();
                                    setDummy(!dummy);
                                }}>Sair</p></button>

                            </div>
                        )} */}
                    </div>

                    <div className='toggler'>
                        <label className='toggler-icon' htmlFor="toggler"><HiChevronDown htmlFor="toggler"></HiChevronDown></label>
                        <input
                            type="checkbox"
                            className='toggler-checkbox'
                            id="toggler"
                            name="toggler"
                            defaultChecked={toggle}
                            onChange={(e) => {
                                //e.preventDefault();
                                e.stopPropagation();
                                setToggle(!toggle);
                                setDummy(!dummy);
                            }}
                        />
                    </div>


                    {isLoginOpen && <Login onRequestLoginClose={setIsLoginOpen} onRequestRegisterClose={setIsRegisterOpen} onRedefinePasswordClose={setIsRedefineOpen} />}
                    {isRegisterOpen && <Register onRequestLoginClose={setIsLoginOpen} onRequestRegisterClose={setIsRegisterOpen} />}
                    {isRedefineOpen && <Redefine onRequestLoginClose={setIsLoginOpen} onRedefinePasswordClose={setIsRedefineOpen} />}
                </div>

            </div>
        </>
    )
}

export default Navbar;