import './Banner.css'
import title from '../../../assets/images/macro_pouso.png'
import noticia_pesquisa_op_carrossel from '../../../assets/images/noticia_pesquisa_op_carrossel.png'
import seminário_sem_fundo from '../../../assets/images/seminário_sem_fundo.png'

import { Link } from 'react-router-dom';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const slides = [
    {
        title: "plano-mobilidade",
        control:
            <div className="btn">
                {/* <Link to="/documentos">Ver Resultados</Link> */}
            </div>,
        image: title,
        class: "",
    },
    {
        title: "plano-mobilidade",
        control:
            <div className="btn">
                {/* <Link to="/documentos">Ver Resultados</Link> */}
            </div>,
        image: noticia_pesquisa_op_carrossel,
        class: "",
    },
    {
        title: "plano-mobilidade",
        control:
            <div className="btn">
                {/* <Link to="/documentos">Ver Resultados</Link> */}
            </div>,
        image: seminário_sem_fundo,
        class: "",
    },

];


function Banner() {

    return (
        <div className='Banner'>
            <div className='center'>
                <div className='banner-items'>

                    <Carousel
                        autoPlay={true}
                        interval={4500}
                        swipeable={true}
                        emulateTouch={true}
                        infiniteLoop={true}
                        showArrows={true}
                        showStatus={false}
                        showThumbs={false}
                    >
                        {slides.map((slide) => (
                            <div key={slide.title} className="image-wrapper">
                                <div>
                                    <img src={slide.image} alt='Plano de mobilidade de Itajubá' />
                                    {slide.control}
                                </div>
                            </div>
                        ))}
                    </Carousel>

                </div>
            </div>
        </div>
    )
}

export default Banner;