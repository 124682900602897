import React from 'react';
import '../Global.css';
import Navbar from '../components/Navbar/Navbar';
import AboutUs from '../components/Home/AboutUs/AboutUs';
import Footer from '../components/Footer/Footer';


function About() {

  return (
    <div className="Home">
      <Navbar />
      <div style={{ paddingTop: '5%' }}><AboutUs /></div>
      <Footer/>
    </div>
  );
}

export default About;