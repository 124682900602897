import './Social.css'

function Social() {
    return (
        <div className='Social' id='social'>
            <div className="social-participation">
                <div className='social--text'>
                    <h2>Participação Social</h2>
                    <p>A participação social é um instrumento fundamental na elaboração dos estudos relacionados 
                        ao Plano de Macrodrenagem pois permite que os problemas levantados, 
                        bem como as soluções propostas, sejam adequados à realidade da população e as 
                        suas necessidades. A população pode participar da elaboração do Plano através de 
                        pesquisas de opinião, espaços para sugestões e comentários 
                        no site e também através das audiências públicas que serão realizadas.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Social;