import React from 'react';
import '../Global.css';
import Navbar from '../components/Navbar/Navbar';
import ContatoContent from '../components/Contato/ContatoContent';
import Footer from '../components/Footer/Footer';


function Contato() {

  return (
    <div className="Home">
      <Navbar />
      <div style={{ paddingTop: '5%' }}><ContatoContent /></div>
      <Footer/>
    </div>
  );
}

export default Contato;