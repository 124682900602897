import { Link } from 'react-router-dom';
import { FaLinkedin, FaEnvelope, FaInstagram } from 'react-icons/fa';

import './Footer.css'

function Footer() {
    return (

        <div className='Footer'>
            <div className='center'>
                <div className='footer-items'>
                    {/* <div className='footer--links'>
                        <Link to="/pesquisa">Pesquisas</Link>
                        <Link to="#">Documentos</Link>
                        <Link to="#">Notícias</Link>
                    </div> */}
                    <div className="footer--media">
                        <a className='icon' href="https://www.sopaneiru.com">
                            <i className='sopa'></i>
                        </a>
                        <a className='linkedin' href="https://www.linkedin.com/company/dac-engenharia/mycompany/">
                            <FaLinkedin className='fa' />
                        </a>
                        <a className='instagram' href="https://www.instagram.com/dac_engenharia_ltda/">
                            <FaInstagram className='fa' />
                        </a>

                        <a className='email' href="mailto:planomacrodrenagem.pa@gmail.com">
                            <FaEnvelope className='fa' />
                        </a>
                    </div>
                    <div className='footer--copyright'>
                        <p>DAC Engenharia</p>
                        <p>© 2007-2023</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;