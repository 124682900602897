import "./NewsContent.css";

import imagem from "../../assets/images/noticia_pesquisa_op.png";
import imagem2 from "../../assets/images/seminário.png";
import { useState } from "react";

function NewsContent() {

    const CardsPorPagina = 2;
    const [paginaAtual, setpaginaAtual] = useState(1);
    const indexUltimoCard = paginaAtual * CardsPorPagina;
    const indexPrimeiroCard = indexUltimoCard - CardsPorPagina;
    const currentCards = json.slice(indexPrimeiroCard, indexUltimoCard);
    const pageCount = Math.ceil(json.length / CardsPorPagina);
    // const orderNews = json.sort();
    //pagination
    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(i);
    }
    function handlePageClick(pageNumber) {
        setpaginaAtual(pageNumber);
    }

    return (
        <div className="NewsContent">
            <div className="center">
                <div className="news-title">
                    <h2>Notícias</h2>
                    <p>
                        Fique por dentro do que acontece em todas as fases do Plano de Macrodrenagem de Pouso Alegre
                    </p>
                </div>
                <div className="news-items">
                    {currentCards.map((item, index) => (
                        <div key={index} className="news-cards">
                            <div className="news-card news-step-3" style={{ overflowWrap: 'break-word', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                                <img src={item.img} alt="" />
                                <h4>{item.title}</h4>
                                <br></br>
                                <p class='item-content' dangerouslySetInnerHTML={{ __html: item.content }} />
                                {item.file && ( // Verifica se há um arquivo associado à notícia
                                    <a className='file-download' target="blank" href={item.file.path} download={`${item.file.filename}.${item.file.extension}`}>Confira o conteúdo apresentado</a>
                                )}

                            </div>
                        </div>
                    ))}
                </div>

                {/*pagination*/}
                <div className="pagination">
                    {pageNumbers.map((pageNumber) => (
                        <button
                            key={pageNumber}
                            onClick={() => handlePageClick(pageNumber)}
                            className={paginaAtual === pageNumber ? "active" : "inactive"}
                        >
                            {pageNumber}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default NewsContent;

const json = [
    {
        id: 1,
        title: "PARTICIPE DA PESQUISA DE OPINIÃO DO PLANO DE MACRODRENAGEM DE POUSO ALEGRE",
        content: `A pesquisa de opinião tem como objetivo realizar um diagnóstico da situação vivenciada pela população de Pouso Alegre frente aos eventos de inundação vivenciados. Ela será realizada de duas maneiras: através de coleta de opinião com os agentes municipais de endemias e também por meio de um formulário eletrônico \r\n(acesse: <a href="https://docs.google.com/forms/d/e/1FAIpQLSdXl3Ej_lwsOtUIhu5UPJFc7yYcop5QG7di7fg3O1e1EPz8cw/viewform?usp=sf_link"}} target="_blank">https://docs.google.com/forms/d/e/1FAIpQLSdXl3Ej_lwsOtUIhu5UPJFc7yYcop5QG7di7fg3O1e1EPz8cw/viewform?usp=sf_link </a>). Participe e nos ajude a tornar o Plano de Macrodrenagem mais participativo!`,
        img: imagem,
        date: "2023-11-15T00:00:00.000Z",
    },
    {
        id: 1,
        title: "Seminário da Cidade do Plano de Macrodrenagem de Pouso Alegre",
        content: `
        O Seminário da Cidade do Plano de Macrodrenagem de Pouso Alegre ocorreu no dia 21 de novembro de 2023 (terça-feira), às 19h na Faculdade de Direito do Sul de Minas, sendo o evento de lançamento do Plano. O evento contou com a presença do Chefe de Gabinete e Secretário de Planejamento Urbano e Meio Ambiente Renato Garcia de Oliveira Dias, o Secretário de Infraestrutura, Obras e Serviços Públicos Alexandre Luciano de Oliveira e a Superintendente de Planejamento Urbano e Meio Ambiente Thais Oliveira Ribeiro, que realizaram a abertura do evento. Após isso, a palavra foi concedida aos responsáveis técnicos do Plano, a empresa DAC Engenharia, que apresentaram para população o que constitui o Plano de Macrodrenagem e a sua funcionalidade para o município, explicando também a metodologia a ser utilizada na construção do projeto e como a participação social será feita nesse processo.
        
         Todos os produtos já elaborados estão disponíveis para download no site do Plano, no qual é possível acessar as informações sobre o planejamento executivo com todas as etapas e produtos que serão entregues ao decorrer de sua elaboração, o cronograma, os agentes envolvidos no processo e os meios de divulgação do Plano.
        
        Por fim, houve um espaço aberto para que as pessoas presentes no evento pudessem fazer sugestões e perguntas referentes ao que foi apresentado. Foi destacada a importância da participação social no decorrer de todo o processo de elaboração do Plano, e que as contribuições trazidas até a equipe técnica serão consideradas na construção do projeto, de modo a se criar soluções que efetivem a mitigação dos problemas de inundação hoje enfrentados pela população.`,
        img: imagem2,
        date: "2023-11-15T00:00:00.000Z",
        // file: "../../uploads/seminario_da_cidade.pdf"
        file:
        {
            path: 'https://dacengenharia.s3.sa-east-1.amazonaws.com/Macro+PA/Seminario_da_Cidade.pdf',
            filename: "Seminário da Cidade",
            extension: "pdf"
        },
    },
];
