import './AboutUs.css'
import people from '../../../assets/images/DAC_pessoas1.jpg'

function AboutUs(){
    return(
        <div className='AboutUs' id='about-us'>
            <div className='center'>
                <div className="aboutUs-items">
                    <div className='aboutUs--title'>
                        <h2>Sobre Nós</h2>
                    </div>
                    <div className="aboutUs--img">
                        <img src={people} alt="Equipe DAC"/>
                    </div>
                    <div className='aboutUs--content'>
                        
                        <p>A DAC Engenharia é uma empresa sediada no sul de Minas Gerais, em Itajubá. 
                            Possui corpo técnico multidisciplinar, com foco no desenvolvimento de projetos 
                            de infraestrutura, desenvolvimento urbano, eficiência energética e geração de 
                            energia e meio ambiente e recursos hídricos.</p>
                        <a href='https://www.dacengenharia.com.br'>Saiba mais!</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;