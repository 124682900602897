import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import './PollList.css'
import { useState } from 'react';

function PollList({ polls }) {

    const concluded = [1, 2, 3];

    const navigate = useNavigate();

    const handleRedirect = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (polls[e.target.id].questionaryLink !== '') {
            window.location.replace(polls[e.target.id].questionaryLink);
        } else {
            navigate(`/pesquisa`, { state: { data: polls[e.target.id] } })
        }
    }

    const CardsPorPagina = 2;
    const [paginaAtual, setpaginaAtual] = useState(1);
    const indexUltimoCard = paginaAtual * CardsPorPagina;
    const indexPrimeiroCard = indexUltimoCard - CardsPorPagina;
    const currentCards = json.slice(indexPrimeiroCard, indexUltimoCard);
    const pageCount = Math.ceil(json.length / CardsPorPagina);
    const orderNews = json.sort();

    return (
        <div className="PollList">
            <div className="center">
                <div className="info-card">
                    {/* <h2 className="info-title">
                        Pesquisa de opinião do Plano de Macrodrenagem de Pouso Alegre
                    </h2> */}
                    {/* <div className="button-info center">
                        <button onClick={(e) =>{
                            e.stopPropagation();
                            e.preventDefault();
                            navigate('/documentos');
                        }}>Ver Resultados</button>
                    </div> */}
                    {currentCards.map((item, index) => (
                        <div style={{ overflowWrap: 'break-word', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                            {/* <img src={item.img} alt="" /> */}
                            <h2 style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>{item.title}</h2>
                            <p style={{ display: "flex", justifyContent: "center", alignItems: 'center' }} dangerouslySetInnerHTML={{ __html: item.content }} />
                        </div>
                    ))}
                </div>



                {/* Não está recebendo respostas! 
                {(() => {
                    if (polls) {
                        return polls.map((questionary, index) => {
                            if (concluded.includes(questionary.questionaryId)) {
                                return (
                                    <div key={index} className="cardPesquisa">
                                        <div className="cardList">
                                            <div className="card-title">
                                                <h2>{questionary.questionaryTitle}</h2>
                                                <p className="blue">Tempo de duração: {questionary.questionaryDuration} minutos</p>
                                            </div>
                                            <div className="button-comecar">
                                                <button id={index} onClick={(event) => alert("Essa pesquisa não está mais recebendo respostas")}>Saiba mais!</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={index} className="cardPesquisa">
                                        <div className="cardList">
                                            <div className="card-title">
                                                <h2>{questionary.questionaryTitle}</h2>
                                                <p className="blue">Tempo de duração: {questionary.questionaryDuration} minutos</p>
                                            </div>
                                            <div className="button-comecar">
                                                <button id={index} onClick={(event) => handleRedirect(event)}>Saiba mais!</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                })()} */}
            </div>
        </div>
    )
}

export default PollList;

const json = [
    {
        id: 1,
        title: "Pesquisa de opinião do Plano de Macrodrenagem de Pouso Alegre",
        content: `\r\ <a>PESQUISA ENCERRADA</a>`,
        // img: imagem,
        date: "2023-11-15T00:00:00.000Z",
    },
];