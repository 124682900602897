import './Plane.css'

function Plane() {
    return (
        <div className='Plane'>
            <div className='plane-about'>
                <div className='plane--text'>
                    <h2>Sobre o plano de Macrodrenagem</h2>
                    <p>O Plano de Macrodrenagem (PMDPA) tem o objetivo de reduzir o impacto das inundações no território de Pouso Alegre,
                        através de planejamento para os próximos 20 anos e servindo como instrumento técnico-institucional para o Município.
                        O PMDPA contará com estudos das planícies de inundação e apresentará propostas de obras e outras ações com o objetivo
                         de melhorar a vida da população local.
</p>
                </div>
            </div>
        </div>
    )
}

export default Plane;